<template>
  <div>
    <!-- Loader -->
    <div v-if="loader" id="preloader">
      <div id="status">
        <div class="spinner">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
        </div>
      </div>
    </div>
    <!-- Loader -->
    <div v-else>
      <!-- Hero Start -->
      <section class="bg-profile d-table w-100 bg-primary"
               style="background: url('/images/company/aboutus.png') center center;">
        <div class="container" >
          <div class="row ">
            <div class="col-lg-12">
              <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-lg-2 col-md-3 text-md-start text-center">
                      <img :src="employee.image?imgShow(employee.image):'/man_face.jpg'"
                           class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                    </div><!--end col-->

                    <div class="col-lg-10 col-md-9 text_custom">
                      <div class="row align-items-end">
                        <div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
                          <h3 class="title mb-0">{{employee.userable?employee.userable.name:''}}</h3>
                          <small class="text-muted h6 me-2">{{ designationByID(employee.designation_id) }}</small>
                          <ul class="list-inline mb-0 mt-3">
                            <li class="list-inline-item me-2">
                              <a href="javascript:void(0)" class="text-muted"
                                                                 title="Instagram">
                                <i data-feather="instagram" class="fea icon-sm me-2">Dept. of {{ departmentByID(employee.department_id) }}</i></a>
                            </li>
                          </ul>
                        </div><!--end col-->

<!--
                        <div class="col-md-5 text-md-end text-center">
                          <ul class="list-unstyled social-icon social mb-0 mt-4">
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"
                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                            title="Add Friend"><i
                                class="uil uil-user-plus align-middle"></i></a></li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"
                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                            title="Messages"><i class="uil uil-comment align-middle"></i></a>
                            </li>
                            <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"
                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                            title="Notifications"><i
                                class="uil uil-bell align-middle"></i></a></li>
                            <li class="list-inline-item"><a href="account-setting.html" class="rounded"
                                                            data-bs-toggle="tooltip" data-bs-placement="bottom"
                                                            title="Settings"><i class="uil uil-cog align-middle"></i></a>
                            </li>
                          </ul>&lt;!&ndash;end icon&ndash;&gt;
                        </div>&lt;!&ndash;end col&ndash;&gt;
-->

                      </div><!--end row-->
                    </div><!--end col-->
                  </div><!--end row-->
                </div>
              </div>
            </div><!--end col-->
          </div><!--end row-->
        </div><!--ed container-->
      </section><!--end section-->
      <!-- Hero End -->

      <!-- Profile Start -->
      <section class="section mt-60">
        <div class="container mt-lg-3">
          <div class="row">
            <div class="container">
<!--              <div class="border-bottom pb-4">
                <p class="text-muted mb-0"></p>
              </div>-->
              <div class="border-bottom pb-4 text_custom bg-custom pl-2 pr-2">
                <div class="row">
                  <div class="col-md-6 mt-4">
                    <h5>Personal Information :</h5>
                    <div class="mt-4">
                      <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                        <div class="flex-1 content ms-3">
                          <span class="title mb-0">Name : </span>
                          <span class="text-muted mb-0">{{ employee.userable?employee.userable.name:'' }}</span>
                        </div>
                      </div>
                      <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                        <div class="flex-1 content ms-3">
                          <span class="title mb-0">Email : </span>
                          <span class="text-muted mb-0">{{ employee.userable?employee.userable.email:'' }}</span>
                        </div>
                      </div>

                      <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                        <div class="flex-1 content ms-3">
                          <span class="title mb-0">Phone Number : </span>
                          <span class="text-muted mb-0">{{ employee.userable?employee.userable.phone_number:'' }}</span>
                        </div>
                      </div>

                      <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                        <div class="flex-1 content ms-3">
                          <span class="title mb-0">Gender : </span>
                          <span class="text-muted mb-0">{{ employee.userable?genderByID(employee.userable.gender_id):'' }}</span>
                        </div>
                      </div>
                      <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                        <div class="flex-1 content ms-3">
                          <span class="title mb-0">Blood Group : </span>
                          <span class="text-muted mb-0">{{ employee.userable?blood_groupByID(employee.userable.blood_group_id):'' }}</span>
                        </div>
                      </div>
                      <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                        <div class="flex-1 content ms-3">
                          <span class="title mb-0">Religion : </span>
                          <span class="text-muted mb-0">{{ employee.userable?religionByID(employee.userable.religion_id):'' }}</span>
                        </div>
                      </div>
                      <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                        <div class="flex-1 content ms-3">
                          <span class="title mb-0">Marital Status : </span>
                          <span class="text-muted mb-0">{{ marital_statusByID(employee.marital_status_id) }}</span>
                        </div>
                      </div>
                    </div>
                  </div><!--end col-->
                  <div class="col-md-6 mt-4 pt-2 pt-sm-0">
                    <h5>Academic Information</h5>

                    <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                      <div class="flex-1 content ms-3">
                        <span class="title mb-0">Employee ID: </span>
                        <span class="text-muted mb-0">{{ employee.institute_emp_id }}</span>
                      </div>
                    </div>
                    
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                      <div class="flex-1 content ms-3">
                        <span class="title mb-0">Designation : </span>
                        <span class="text-muted mb-0">{{ designationByID(employee.designation_id) }}</span>
                      </div>
                    </div>

                    <!-- <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                      <div class="flex-1 content ms-3">
                        <span class="title mb-0">Version : </span>
                        <span class="text-muted mb-0">{{ versionByID(employee.version_id) }}</span>
                      </div>
                    </div>
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                      <div class="flex-1 content ms-3">
                        <span class="title mb-0">Shift : </span>
                        <span class="text-muted mb-0">{{ shiftByID(employee.shift_id) }}</span>
                      </div>
                    </div> -->
                    <!-- <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                      <div class="flex-1 content ms-3">
                        <span class="title mb-0">Wing : </span>
                        <span class="text-muted mb-0">{{ wingByID(employee.wing_id) }}</span>
                      </div>
                    </div> -->
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                      <div class="flex-1 content ms-3">
                        <span class="title mb-0">Department : </span>
                        <span class="text-muted mb-0">{{ departmentByID(employee.department_id) }}</span>
                      </div>
                    </div>
                    <div class="d-flex key-feature align-items-center p-3 rounded shadow mt-1">
                      <div class="flex-1 content ms-3">
                        <span class="title mb-0">Joining Date: </span>
                        <span class="text-muted mb-0">{{ employee.join_date }}</span>
                      </div>
                    </div>
                    
                  </div><!--end col-->
                </div><!--end row-->
                <!-- <div class="mt-5" v-if="employee.education_qualifications && employee.education_qualifications.length>0">
                  <h4>Educational Qualifications</h4>
                  <table class="table table-bordered text-custom2">
                    <thead class="text_custom">
                      <tr style="background-color:#878787; color: white; font-weight: bold;font: 1rem 'MutatorSans', sans-serif;">
                        <td>Degree Name</td>
                        <td>Passing Year</td>
                        <td v-if="employee.is_result_shown == 1">Result</td>
                        <td>Board/University</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr  v-for="(q,key) in employee.education_qualifications" :key="key">
                        <td>{{q.name_of_exam}}</td>
                        <td>{{q.exam_year}}</td>
                        <td v-if="employee.is_result_shown ==1">{{q.result}}</td>
                        <td>{{q.board_or_university}}</td>
                      </tr>
                    </tbody>

                  </table>
                </div> -->
                <!-- <div class="mt-5" v-if="employee.employee_experiences && employee.employee_experiences.length>0">
                  <h4>Professional Experiences</h4>
                  <table class="table table-bordered text-custom2">
                    <thead class="text_custom">
                        <tr style="background-color:#878787; color: white; font-weight: bold;font: 1rem 'MutatorSans', sans-serif;">
                          <td>Institute</td>
                          <td>Designation</td>
                          <td>Worked(Year)</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(r,key2) in employee.employee_experiences" :key="key2">
                          <td>{{r.institute}}</td>
                          <td>{{r.designation}}</td>
                          <td>{{r.number_of_year}}</td>
                        </tr>
                    </tbody>
                  </table>
                </div> -->
              </div>
            </div><!--end col-->
          </div><!--end row-->
        </div><!--end container-->
      </section><!--end section-->
      <!-- Profile End -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'FacultyDetails',
  props: {
    id:{required:true},
  },
  data(){
    return {
      employee:{},
    }
  },
  created (){
    if(this.employees.length <1) this.$store.dispatch('GET_ALL_EMPLOYEE');
    if(this.departments.length <1) this.$store.dispatch('GET_ALL_COMMON_INFO');
    this.getEmployee();
  },
  methods:{
    async getEmployee(){
      this.employee = await this.$store.getters.employeeByID(this.id)??{};
    },
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
  },
  computed:{
    employees(){
      return this.$store.getters.employees;
    },
    departments(){
      return this.$store.getters.departments;
    },
    designationByID(){
      return this.$store.getters.designationByID;
    },
    departmentByID(){
      return this.$store.getters.departmentByID;
    },
    genderByID(){
      return this.$store.getters.genderByID;
    },
    blood_groupByID(){
      return this.$store.getters.blood_groupByID;
    },
    religionByID(){
      return this.$store.getters.religionByID;
    },
    versionByID(){
      return this.$store.getters.versionByID;
    },
    shiftByID(){
      return this.$store.getters.shiftByID;
    },
    wingByID(){
      return this.$store.getters.wingByID;
    },
    marital_statusByID(){
      return this.$store.getters.marital_statusByID;
    },
    loader(){
      return this.$store.getters.loader;
    }
  },
  watch:{
    id(){
      this.getEmployee();
    },
    employees(){
      this.getEmployee();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
